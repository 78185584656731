html, body, #root{
  height: 100%;
}

.custom-qr div, .app-border{
  border: 5px solid #F7DCCF !important;
  box-shadow: none !important;
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 3 !important;
}

.app-border{
  background-color: white;
  width: 78%;
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  
}

p,h1,h2,h3,h4,h5,h6{
  padding: 0;
  margin: 0;
}

.custom-qr video{
  z-index: 4;
}
.p-relative{
  position: relative;
}
.m3{
  margin-bottom: 10px;
}
.app-button{
  background-color: #295102;
  color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-top: 20px;
  width: 100%;
  justify-content: center;
}
.app-button >img{
  margin-right: 10px;
}
.c-pointer{
  cursor: pointer;
}
.unstyle-button {
  border: none;
  outline: none;
}

  .unstyle-button:focus {
      outline: none;
  }
.p-fixed{
  position: fixed;
}
.p-absolute{
  position: absolute;
}

.app-bg{
  width: 100%;
  height: 100%;
  background-image: url('assets/efosafrancis.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.z-index-3{
  z-index: 3;
}

.img-cont{
  bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bouquet{
  width: 120px;
}
.app-content{
  height: 100%;
}


.app-content-children{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.flex-one{
  flex: 1;
}

.main-image{
  top: 0;
  object-fit: cover;
  width: 100%;
}

.other-image{
  bottom: 0;
  z-index: 2;
  height: 80%;
  width: 100%;
}
.loader{
  position: fixed;
  overflow: hidden;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
/* Loader from https://loading.io/css/ */
.lds-spinner {
  z-index: 10;
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  z-index: 10;
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Toast css by https://codepen.io/kipp0/pen/pPNrrj */
#toast {
  visibility: hidden;
  max-width: 50px;
  height: 50px;
  /*margin-left: -125px;*/
  margin: auto;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;

  position: fixed;
  z-index: 10;
  left: 0;right:0;
  bottom: 30px;
  font-size: 17px;
  white-space: nowrap;
}
#toast #img{
width: 50px;
height: 50px;
z-index: 10;
  
  float: left;
  
  padding-top: 16px;
  padding-bottom: 16px;
  
  box-sizing: border-box;

  
  background-color: #111;
  color: #fff;
}
#toast #desc{
  z-index: 10;

  
  color: #fff;
 
  padding: 16px;
  
  overflow: hidden;
white-space: nowrap;
}

#toast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, expand 0.5s 0.5s,stay 3s 1s, shrink 0.5s 2s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, expand 0.5s 0.5s,stay 3s 1s, shrink 0.5s 4s, fadeout 0.5s 4.5s;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes expand {
  from {min-width: 50px} 
  to {min-width: 350px}
}

@keyframes expand {
  from {min-width: 50px}
  to {min-width: 350px}
}
@-webkit-keyframes stay {
  from {min-width: 350px} 
  to {min-width: 350px}
}

@keyframes stay {
  from {min-width: 350px}
  to {min-width: 350px}
}
@-webkit-keyframes shrink {
  from {min-width: 350px;} 
  to {min-width: 50px;}
}

@keyframes shrink {
  from {min-width: 350px;} 
  to {min-width: 50px;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 60px; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 60px; opacity: 0;}
}